import React, { FC } from 'react';

import { Layout } from 'layout';
import { graphql } from 'gatsby';
import SitemapCategory from 'components/SitemapCategory';

import './sitemap.scss';
import createBreadcrumbs from 'utils/createBreadcrumbs';
import { getPathForLanguage } from 'utils/functions';
import Seo from 'common/Seo';
import { toBoolean } from 'utils/stringUtils/stringUtils';

const SitemapPage: FC<SitemapTypes.SitemapProps> = ({
  data: {
    sitemap: { nodes: sitemapNodes },
    tipsAndTricksArticles: { nodes: tipsAndTricksArticleNodes },
    tipsAndTricks: { nodes: tipsAndTrickNodes },
    signUp: { nodes: signUpNodes },
    contactUs: { nodes: contactUsNodes },
    aboutUs: { nodes: aboutUsNodes },
    tikTokRules: { nodes: tikTokRulesNodes },
    lds: { nodes: ldsNodes },
    productCategories: { nodes: productCategoriesNodes },
    products: { nodes: productNodes },
    allSitePage: { nodes },
    contest,
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const {
    langProps,
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs },
    title: sitemapTitle,
  } = sitemapNodes[0];

  const tipsAndTricksArticless = tipsAndTricksArticleNodes.map((article) => {
    const {
      id,
      title,
      seoProps: { seoMetaTitle: tipsAndTricksSeoMetaTitle },
      langProps: { urls: url, lang },
    } = article;

    return { id, name: tipsAndTricksSeoMetaTitle || title, url: getPathForLanguage(url, lang) };
  });

  const tipsAndTricksListing = {
    ...tipsAndTrickNodes[0],
    subLinks: tipsAndTricksArticless,
  };

  const findYourVeetCategory = productCategoriesNodes
    .filter((category) => !category.variant)
    .map((category) => {
      const {
        name,
        url,
        seoProps: { seoMetaTitle: findYourVeetSeoMetaTitle },
      } = category;

      return {
        name: findYourVeetSeoMetaTitle || name,
        url,
      };
    })[0];

  const productsCategories = productCategoriesNodes
    .filter((category) => category.variant)
    .map((category) => {
      const {
        id,
        name,
        url,
        variant,
        seoProps: { seoMetaTitle: productsSeoMetaTitle },
      } = category;

      return {
        id,
        name: productsSeoMetaTitle || name,
        url,
        subLinks: productNodes
          .filter((product) => product.tags[0].name.includes(variant!))
          .map((product) => {
            const {
              name: productName,
              alternativeName,
              url: productUrl,
              seoProps: { seoMetaTitle: productSeoMetaTitle },
            } = product;

            return {
              name: productSeoMetaTitle || productName || alternativeName,
              url: productUrl,
            };
          }),
      };
    });

  const productsListing = {
    ...findYourVeetCategory,
    subLinks: productsCategories,
  };

  const tikTokListing = tikTokRulesNodes.filter((node) => toBoolean(node.tikTokRulesVisibility));

  const contestsLinks =
    (contest && contest.nodes.filter((node) => toBoolean(node.contestVisibility))) || [];

  const categories = [
    productsListing,
    tipsAndTricksListing,
    ...signUpNodes,
    ...contactUsNodes,
    ...aboutUsNodes,
    ...sitemapNodes,
    ...tikTokListing,
    ...ldsNodes,
    ...contestsLinks,
  ];

  const breadcrumbs = {
    breadCrumb: createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname),
  };

  return (
    <Layout breadcrumbs={breadcrumbs} langProps={{ ...langProps }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          lang: langProps.lang,
        }}
      />
      <div className="sitemap">
        {sitemapTitle ? <h1 className="sitemap__title">{sitemapTitle}</h1> : null}
        <div className="sitemap__nodes">
          {categories.map((category) => {
            return (
              <ul className="sitemap__node" key={category.url}>
                <SitemapCategory {...category} />
              </ul>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($pageId: String, $tipsAndTricksParentId: Int, $lang: String) {
    sitemap: allUmbracoSitemap(filter: { id: { eq: $pageId } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        name
        url
        title
      }
    }
    tipsAndTricksArticles: allUmbracoArticles(
      filter: { parentId: { eq: $tipsAndTricksParentId }, langProps: { lang: { eq: $lang } } }
    ) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        id
        title
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
      }
    }
    tipsAndTricks: allUmbracoTipsAndTricks(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        name
        url
      }
    }
    signUp: allUmbracoSignUp(filter: { lang: { eq: $lang } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        name
        url
      }
    }
    contactUs: allUmbracoContactUs(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        name
        url
      }
    }
    aboutUs: allUmbracoAboutUs(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        name
        url
      }
    }
    tikTokRules: allUmbracoTikTokRules(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        name
        url
        tikTokRulesVisibility
      }
    }
    lds: allLds(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        name
        url
      }
    }
    productCategories: allUmbracoProductsList(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        name
        id
        url: link
        variant
        variants
      }
    }
    products: allSalsifyProducts(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        url
        id
        name: productTitle
        alternativeName: productTitleWithSize
        tags {
          name
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
    contest: allUmbracoContests(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        name
        url
        contestVisibility
      }
    }
  }
`;

export default SitemapPage;
